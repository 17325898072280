const items = [
    { id: 1,
      title:"الشيخ عبد الحميد كشك ونهاية القذافي ",
      url: 'https://ia800800.us.archive.org/23/items/Kishk-Muammar-Gaddafi/01.mp3'
    },
    { id: 2,
      title:"علاج المسلمين في الكنائس على يد القساوسة ",
      url: 'https://ia903101.us.archive.org/3/items/kiosk-mp3-Forbidden/%D8%B9%D9%84%D8%A7%D8%AC%20%D8%A7%D9%84%D9%85%D8%B3%D9%84%D9%85%D9%8A%D9%86%20%D9%81%D9%8A%20%D8%A7%D9%84%D9%83%D9%86%D8%A7%D8%A6%D8%B3%20%D8%B9%D9%84%D9%89%20%D9%8A%D8%AF%20%D8%A7%D9%84%D9%82%D8%B3%D8%A7%D9%88%D8%B3%D8%A907.mp3'
    },
    { id: 3,
      title:"طرائف الشيخ كشك",
      url: 'https://ia801605.us.archive.org/14/items/Kiosks-Jokes-mp3/%D8%B7%D8%B1%D8%A7%D8%A6%D9%81-%D8%A7%D9%84%D8%B4%D9%8A%D8%AE-%D9%83%D8%B4%D9%83.mp3'
    },
    { id: 4,
      title:" هلاك شاه ايران",
      url: 'https://ia800302.us.archive.org/25/items/way_G5/g%20%281%29.mp3'
    },
    { id: 5,
      title:"أهوال يوم القيامة",
      url: 'https://ia800302.us.archive.org/25/items/way_G5/g%20%283%29.mp3'
    },
    { id: 6,
      title:"أنواع الكفر",
      url: 'https://ia600302.us.archive.org/25/items/way_G5/g%20%284%29.mp3'
    },
    { id: 7,
      title:"زيد بن حارثة والرد على المستشرقين",
      url: 'https://ia800302.us.archive.org/25/items/way_G5/g%20%285%29.mp3'
    },
    { id: 8,
      title:"قصة سيدنا على وسيدنا عمر",
      url: 'https://ia800302.us.archive.org/25/items/way_G5/g%20%286%29.mp3'
    },
    { id: 9,
      title:"أذكار كان نبي الرحمة يرددها",
      url: 'https://ia903102.us.archive.org/2/items/Recitation-Prayer/%D8%A3%D8%B0%D9%83%D8%A7%D8%B1%20%D9%83%D8%A7%D9%86%20%D9%86%D8%A8%D9%8A%20%D8%A7%D9%84%D8%B1%D8%AD%D9%85%D8%A9%20%D9%8A%D8%B1%D8%AF%D8%AF%D9%87%D8%A7.mp3'
    },
    { id: 10,
      title:"التوبة والدعاء ",
      url: 'https://ia803102.us.archive.org/2/items/Recitation-Prayer/%D8%A7%D9%84%D8%AA%D9%88%D8%A8%D8%A9%20%D9%88%20%D8%A7%D9%84%D8%AF%D8%B9%D8%A7%D8%A1.mp3'
    },
    { id: 11,
      title:"عشر آيات للتحصين من السحر",
      url: 'https://ia803102.us.archive.org/2/items/Recitation-Prayer/%D8%A7%D9%84%D8%B4%D9%8A%D8%AE%20%D9%83%D8%B4%D9%83%20%D8%B9%D8%B4%D8%B1%20%D8%A2%D9%8A%D8%A7%D8%AA%20%D9%84%D9%84%D8%AA%D8%AD%D8%B5%D9%8A%D9%86%20%D9%85%D9%86%20%D8%A7%D9%84%D8%B3%D8%AD%D8%B1.mp3'
    },
    { id: 12,
      title:"ماذا تقول إذا إشتدت عليك الكروب",
      url: 'https://ia803102.us.archive.org/2/items/Recitation-Prayer/%D8%A7%D9%84%D8%B4%D9%8A%D8%AE%20%D9%83%D8%B4%D9%83%20%D9%85%D8%A7%D8%B0%D8%A7%20%D8%AA%D9%82%D9%88%D9%84%20%D8%A5%D8%B0%D8%A7%20%D8%A5%D8%B4%D8%AA%D8%AF%D8%AA%20%D8%B9%D9%84%D9%8A%D9%83%20%D8%A7%D9%84%D9%83%D8%B1%D9%88%D8%A8.mp3'
    },
    { id: 13,
      title:"دعاء الاستيقاظ من النوم",
      url: 'https://ia803102.us.archive.org/2/items/Recitation-Prayer/%D8%AF%D8%B9%D8%A7%D8%A1%20%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%8A%D9%82%D8%A7%D8%B8%20%D9%85%D9%86%20%D8%A7%D9%84%D9%86%D9%88%D9%85.mp3'
    },
    { id: 14,
      title:"ذكر الله",
      url: 'https://ia903102.us.archive.org/2/items/Recitation-Prayer/%D8%B0%D9%83%D8%B1%20%D8%A7%D9%84%D9%84%D9%87.mp3'
    },
    { id: 15,
      title:"العظماء",
      url: 'https://ia600603.us.archive.org/19/items/Greats-islam/01.mp3'
    },
    // { id: 15,
    //   title:"",
    //   url: ''
    // },

]
export default items